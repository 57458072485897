import { Modal, Form, Input, message } from "antd";
import { useState } from "react";
import { resetUserPassword } from "../../../actions";
import responseErrorService from "../../../utilities/responseErrorHandling";

const ForgotPasswordModal = ({ open, onOk, onCancel, email, setEmail }) => {
  const [isReseting, setIsReseting] = useState(false);

  const handleOnOk = () => {
    setIsReseting(true);
    resetUserPassword({ email: email })
      .then((response) => {
        message.success("Password reset link has been sent to your email.");
        onOk();
      })
      .catch((err) => {
        responseErrorService.handle(err);
      })
      .finally(() => {
        setIsReseting(false);
      });
  };

  return (
    <Modal title="Reset Password" open={open} onOk={handleOnOk} onCancel={onCancel} okText="Send Password Reset Link" confirmLoading={isReseting}>
      <Form layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
          extra="We'll send you an email with a link to reset your password"
        >
          <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ForgotPasswordModal;
