import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LockOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, Card, message } from "antd";
import PhoneInput from "antd-phone-input";
import { registerAccount } from "../../actions";
import {} from "../../global_constants";

import AccountHook from "../../hooks/account";
import { Link } from "react-router-dom";
import responseErrorService from "../../utilities/responseErrorHandling";
import ReCAPTCHA from "react-google-recaptcha";

const validPhoneNumberValidation = (_, { valid }) => {
  // if (valid(true)) return Promise.resolve(); // strict validation
  if (valid()) return Promise.resolve(); // non-strict validation
  return Promise.reject("Invalid phone number");
};

const Register = () => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const onSubmit = (values) => {
    const phoneNumberObj = values["phone"];
    values["phone"] = `${phoneNumberObj.countryCode}${phoneNumberObj.areaCode}${phoneNumberObj.phoneNumber}`;

    if (!captchaValue) {
      message.error("Please complete the CAPTCHA!");
      return;
    }
    values.captcha = captchaValue;

    setProcessing(true);
    registerAccount(values)
      .then((response) => {
        const { data } = response;
        message.success("Account created successfully! Please check your email to verify your account.", 10);
        history.push("/?extra=verify_email");
      })
      .catch((err) => {
        responseErrorService.handle(err);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const validPasswordValidation = (_, value) => {
    if (!value || form.getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject("The two passwords that you entered do not match!");
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {}, []);

  return (
    <div className="login-page">
      <Card bordered>
        <div className="image-card" style={{ marginBottom: "15px", backgroundColor: "black" }} />
        <p className="text-2xl semi-bold mb-16">Create an Account</p>
        <Form layout="vertical" form={form} onFinish={(values) => onSubmit(values)}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input size="middle" prefix={<UserOutlined />} placeholder="First Name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input size="middle" prefix={<UserOutlined />} placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="company_name"
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please input your Company Name!",
              },
            ]}
          >
            <Input size="middle" prefix={<ShopOutlined />} placeholder="Company Name" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your Phone Number!",
              },
              { validator: validPhoneNumberValidation },
            ]}
          >
            <PhoneInput enableSearch />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-Mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input size="middle" prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters!",
              },
            ]}
          >
            <Input.Password size="middle" prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: "Please confirm your Password!",
              },
              {
                validator: validPasswordValidation,
              },
            ]}
          >
            <Input.Password size="middle" prefix={<LockOutlined />} placeholder="Confirm Password" />
          </Form.Item>

          <ReCAPTCHA sitekey="6LcFsCocAAAAAINBl3Qkn4-lGo_W6x9ShKtnXgCt" onChange={onCaptchaChange} />

          <p style={{ marginTop: "10px", marginBottom: "10px" }}>
            By creating an account, you agree to our{" "}
            <a href="https://manyfastscan.com/policies" target="_blank" rel="noreferrer" style={{ color: "#1677ff" }}>
              Policies
            </a>
            .
          </p>
          <Form.Item noStyle>
            <Button block type="primary" className="mt-8" htmlType="submit" loading={processing}>
              Register
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
export default Register;
