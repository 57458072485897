import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, Card, Alert } from "antd";

import { loginForCheckoutUser, loginUser } from "../../actions";
import { AUTHORIZATION_KEY, DEVICE_ID } from "../../global_constants";

import AccountHook from "../../hooks/account";
import { Link } from "react-router-dom";
import responseErrorService from "../../utilities/responseErrorHandling";
import { getQueryStringValue } from "../../utilities/queryString";
import ForgotPasswordModal from "./forgotPasswordModal"; // Import the new component

const Login = ({ setAccountData }) => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [isDisplayMultipleDevicesAlert, setIsDisplayMultipleDevicesAlert] = useState(false);
  const [isDisplayVerifyEmailAlert, setIsDisplayVerifyEmailAlert] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(null);

  const onSubmit = (values) => {
    setProcessing(true);
    loginUser(values)
      .then((response) => {
        setProcessing(false);
        setAccountData(response);
        localStorage.setItem(AUTHORIZATION_KEY, response.token);
        localStorage.setItem(DEVICE_ID, response.device_id);
      })
      .catch((err) => {
        setProcessing(false);
        // check if response has plan_expired key
        if (err?.response?.data?.plan_expired) {
          setProcessing(true);
          loginForCheckoutUser(values)
            .then((response) => {
              setAccountData(response);
              localStorage.setItem(AUTHORIZATION_KEY, response.token);
              localStorage.setItem(DEVICE_ID, response.device_id);
              history.push("/billing");
            })
            .catch((err) => {
              responseErrorService.handle(err);
            })
            .finally(() => {
              setProcessing(false);
            });
        } else {
          responseErrorService.handle(err);
        }
      });
  };

  const handleForgotPassword = () => {
    setIsForgotPasswordModalOpen(true);
  };

  const handleForgotPasswordOk = () => {
    setIsForgotPasswordModalOpen(false);
  };

  const handleForgotPasswordCancel = () => {
    setIsForgotPasswordModalOpen(false);
  };

  useEffect(() => {
    const extra = getQueryStringValue("extra");
    if (extra) {
      if (extra === "invalid_device") {
        setIsDisplayMultipleDevicesAlert(true);
      }
      if (extra === "verify_email") {
        setIsDisplayVerifyEmailAlert(true);
      }
    }
  }, []);

  return (
    <div className="login-page">
      <Card bordered>
        <div className="image-card" style={{ marginBottom: "15px", backgroundColor: "black" }} />

        <p className="text-2xl semi-bold mb-16">Login</p>

        <If condition={isDisplayMultipleDevicesAlert}>
          <Alert
            style={{ marginBottom: "15px" }}
            message="Multiple Devices Detected"
            description="We've noticed you're attempting to use the app on multiple devices. Please log in again to continue. Our policy allows only one mobile and one web user per account simultaneously. If you're not using multiple devices of the same type (mobile and web), please contact support for assistance."
            type="warning"
            showIcon
            closable
          />
        </If>

        <If condition={isDisplayVerifyEmailAlert}>
          <Alert
            style={{ marginBottom: "15px" }}
            message="Email Verification Required"
            description="Please verify your email address by clicking the link sent to your email. If you haven't received the email, please check your spam folder or contact support."
            type="info"
            showIcon
            closable
          />
        </If>

        <Form form={form} onFinish={(values) => onSubmit(values)}>
          <Form.Item
            name="username"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input size="middle" prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password size="middle" prefix={<LockOutlined />} placeholder="Password" />
          </Form.Item>
          {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}
          <Form.Item noStyle>
            <Button block type="primary" className="mt-8" htmlType="submit" loading={processing}>
              Login
            </Button>
          </Form.Item>
        </Form>
        <Button type="link" onClick={handleForgotPassword} style={{ marginTop: "10px" }}>
          Forgot Password?
        </Button>
        <p
          style={{
            marginTop: "15px",
          }}
        >
          Don't have an Account? Click{" "}
          <Link to={"/register"} style={{ color: "#1677ff" }}>
            here to register new Account
          </Link>
        </p>
      </Card>
      <ForgotPasswordModal
        open={isForgotPasswordModalOpen}
        onOk={handleForgotPasswordOk}
        onCancel={handleForgotPasswordCancel}
        email={forgotPasswordEmail}
        setEmail={setForgotPasswordEmail}
      />
    </div>
  );
};
export default AccountHook(Login);
