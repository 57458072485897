import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Modal, Form, message, DatePicker, Radio, Select } from "antd";
import { startManifestGroupDownload, startManifestDownload, startReceivingDownload, getAllConditionFilters, getAllCategories } from "../../actions";
import { MANIFEST_ITEM_DOWNLOAD_TYPES } from "../../constants";
import { DATETIME_BACKEND_FORMAT, DISPLAY_FULL_DATETIME_FORMAT } from "../../global_constants";
import dayjs from "dayjs";
import responseErrorService from "../../utilities/responseErrorHandling";
import { conditionFiltersMap } from "../../helpers";

const { RangePicker } = DatePicker;

const StartDownloadModal = ({ isModalOpen, setIsModalOpen, manifest, manifestGroup, receivingDownload }) => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [isDownloading, setIsDownloading] = useState(false);
  const [categories, setCategories] = useState([]);

  const [conditionFilters, setConditionFilters] = useState([]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const startDownload = (values) => {
    let selectedMode = values.item_download_type;
    /*
      Specified by Evan:
      Manifest With Photos
      - all manifest items with photos
      - featured only items with photos

      Manifest only
      - all manifest items without photos
      - featured items without photos

      Featured items:
      - featured items with photos
    */
    if (selectedMode === "ManifestWithPhotos") {
      values.include_photos = true;
      values.item_download_type = "All";
    } else if (selectedMode === "ManifestOnly") {
      values.include_photos = false;
      values.item_download_type = "All";
    } else if (selectedMode === "FeaturedOnly") {
      values.include_photos = true;
      values.item_download_type = "OnlyFeatured";
    } else {
      message.error("Invalid download type - please contact support");
      return;
    }

    let functionToCall = null;
    if (manifest) {
      values.manifest_id = manifest.id;
      functionToCall = startManifestDownload;
    } else if (manifestGroup) {
      values.manifest_group_id = manifestGroup.id;
      functionToCall = startManifestGroupDownload;
    } else if (receivingDownload) {
      functionToCall = startReceivingDownload;
    } else {
      message.error("Invalid download type - please contact support");
      return;
    }
    let startDatetime = values?.start_end_datetime?.[0];
    let endDatetime = values?.start_end_datetime?.[1];
    if (startDatetime) {
      values.start_datetime = startDatetime.format(DATETIME_BACKEND_FORMAT);
    }
    if (endDatetime) {
      values.end_datetime = endDatetime.format(DATETIME_BACKEND_FORMAT);
    }

    if (values.conditions) {
      values.conditions = values.conditions.join(",");
    }

    setIsDownloading(true);
    functionToCall(values)
      .then((response) => {
        setIsDownloading(false);
        const { data } = response;
        const { id } = data;
        setIsModalOpen(false);
        history.push(`/download-logs?page=1&preselect_live_log_listen=${id}`);
      })
      .catch((err) => {
        setIsDownloading(false);
        responseErrorService.handle(err);
      });
  };

  const getConditionFilters = () => {
    getAllConditionFilters()
      .then((response) => {
        const { data } = response;
        setConditionFilters(
          data.map((item) => {
            item.key = item.id;
            return item;
          })
        );
      })
      .catch((err) => {
        responseErrorService.handle(err);
      });
  };

  const getCategories = () => {
    getAllCategories()
      .then((response) => {
        const { data } = response;
        setCategories(data);
      })
      .catch((err) => {
        responseErrorService.handle(err);
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      getConditionFilters();
      getCategories();
      setTimeout(() => {
        form.setFieldsValue(manifest);
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  return (
    <Modal
      title={`Start Download - ${receivingDownload ? "Receiving Items" : manifest ? manifest?.name : manifestGroup?.name}`}
      okText={"Download"}
      cancelText="Close"
      open={isModalOpen}
      onOk={() => form.submit()}
      onCancel={() => handleCancel()}
      confirmLoading={isDownloading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => startDownload(values)}
        initialValues={{
          item_download_type: MANIFEST_ITEM_DOWNLOAD_TYPES[0].value, // ManifestWithPhotos
          start_end_datetime: [dayjs().add(-14, "d"), dayjs()],
        }}
      >
        <Form.Item label="Download Type" name="item_download_type" rules={[{ required: true, message: "Please select download type" }]}>
          <Radio.Group>
            {MANIFEST_ITEM_DOWNLOAD_TYPES.map((item) => {
              return <Radio value={item.value}>{item.label}</Radio>;
            })}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Created/Updated Start - End"
          name="start_end_datetime"
          extra={
            "Select the date range during which you created or updated your listings. If an item was modified after its creation, the updated date will be used to retrieve data within this date range"
          }
        >
          <RangePicker
            allowClear
            format={DISPLAY_FULL_DATETIME_FORMAT}
            showTime
            defaultOpenValue={[dayjs().add(-14, "d"), dayjs()]}
            presets={[
              {
                label: "Yesterday",
                value: [dayjs().add(-1, "d"), dayjs()],
              },
              {
                label: "Last 7 Days",
                value: [dayjs().add(-7, "d"), dayjs()],
              },
              {
                label: "Last 14 Days",
                value: [dayjs().add(-14, "d"), dayjs()],
              },
              {
                label: "Last 30 Days",
                value: [dayjs().add(-30, "d"), dayjs()],
              },
              {
                label: "Last 90 Days",
                value: [dayjs().add(-90, "d"), dayjs()],
              },
            ]}
          />
        </Form.Item>

        {/* only show the conditions filters on manifest and manifest group download */}
        <If condition={manifest || manifestGroup}>
          <Form.Item name="conditions" label="Condition Filter(s)">
            <Select mode="multiple" options={conditionFilters.map((cf) => conditionFiltersMap(cf, categories))} />
          </Form.Item>
        </If>
      </Form>
    </Modal>
  );
};

export default StartDownloadModal;
